//import {context} from 'o365-modules';

export default function useFieldFilterControls(filterItem:any, filterObject:any) {
    const toggle = () => {
        //const item = filterObject.getItem(columnName);
        filterItem.excluded = !filterItem.excluded;
        doFilter();
    }

    const reset = () => {
       // const item = filterObject.getItem(columnName);
        filterItem.excluded = false;
        filterItem.resetItem(); 
        doFilter();
    }
        
    const remove = () => {
        filterObject.removeItem(filterItem.column);
        doFilter();
    }

    const applySearchEnter = () => {
        doFilter();
    }

    const applyDateBetween = () => {
      //  const item = filterObject.getItem(columnName);
        filterItem.selectedValue = [filterItem.combinedStartValue, filterItem.combinedEndValue];
        doFilter();
    }

    const applyBit = (evt:any) => {
        //const item = filterObject.getItem(columnName);
        filterItem.selectedValue = evt.target.value;
        doFilter();
    }

    const doFilter = () => {
       filterObject.apply();
    }

    const getFiltersList = (context:any) =>{
        return filterObject.filtersListDO.data.filter((item:any)=>{
            if(item.Mine) return true;
            if(item.SharedPerson) return true;
            if(item.AccessIdPath  == null && item.OrgUnit_ID == context.id) return true;
            if(context.idPath.startsWith(item.AccessIdPath)) return true;

            return false;
        });
    }



    return { toggle, reset, remove, applySearchEnter, applyDateBetween, applyBit, doFilter, getFiltersList };
}
